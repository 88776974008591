<template>
  <div class="withdraw">
    <div class="withdrawTitle">
      <!-- <div class="myWithdraw">
        <p>我要提现</p>
      </div> -->
    </div>
    <div class="withdrawContent colorFont">
      <p v-if="isQyInfo == 0">您还未信息上报，请先去信息上报页面进行上报</p>
      <p v-if="isQyInfo == 1">
        <span
          v-if="isQyBank !== ''"
          style="color: red; font-size:18px;font-weight:700"
          >{{ isQyBank | BankSignStatus }}</span
        >
      </p>
      <p v-if="isQyInfo == 8">您的信息上报失败, 请先去信息上报页面重新填写</p>
      <p v-if="isQyInfo == 9">您的信息正在上报，请等待</p>
    </div>
    <div class="bankTips">
      <p class="title" style="font-size:16px">温馨提示：出金时间为交易日{{ time }}</p>
      <!-- <p class="desc" style="font-size:18px">出金时间为交易日{{ time }}</p> -->
    </div>
    <div class="withdrawContent" v-if="isQyInfo == 1 && isQyBank == 1">
      <!-- <div class="withdrawContent"> -->
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="出金金额" prop="amount">
          <el-input v-model="form.amount" type="number"></el-input>
        </el-form-item>
        <el-form-item label="资金密码" prop="pwd">
          <el-input v-model="form.pwd" type="password"></el-input>
        </el-form-item>
        <!-- <el-form-item label="交易附言" prop="postScript">
          <el-input v-model="form.postScript"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="收款账号" prop="bankAccount">
          <el-input v-model="form.bankAccount" :disabled="true"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="银行资金账号" prop="fundNo">
          <el-input v-model="form.fundNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="收款行号" prop="rcvBankNo">
          <el-input v-model="form.rcvBankNo" :disabled="true"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="收款行名" prop="rcvBankName">
          <el-input v-model="form.rcvBankName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="收款户名" prop="rcvAcctName">
          <el-input v-model="form.rcvAcctName" :disabled="true"></el-input>
        </el-form-item> -->
        <div class="itemAccount">
          <p style="color:#303133">账户资金信息</p>
          <div class="bottomModel">
            <p>可出资金：{{ myAccount.canBeMargin | toThousands }}</p>
            <p>可用金额：{{ myAccount.avilibleMargin | toThousands }}</p>
            <p>上日权益：{{ myAccount.lastBalance | toThousands }}</p>
          </div>
          <!-- <span class="title">&nbsp;&nbsp;可提现金额 : </span>
          <span class="itemData">{{ isNaN(accountInfo.availableBalance)?'--' :accountInfo.availableBalance }}元</span> -->
        </div>
        <div class="submitButton">
          <el-button type="primary" @click="submitApply()" :disabled="isDisable"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("出金金额不能为0，请重新输入"));
      } else if (value > this.myAccount.canBeMargin) {
        return callback(new Error("出金金额大于可出资金，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      time: window.g.AMOUNT_TIME,
      form: {
        amount: "",
        pwd: null
      },
      // form: {
      //   amount : "",
      //   pwd : null,
      //   symbolId : 1,//
      //   bankAccount : null,//收款账号
      //   fundNo : null,//银行资金账
      //   rcvBankNo : null,//收款行号
      //   rcvBankName : null,//收款行名
      //   rcvAcctName : null,//收款户名
      //   memType : 1,
      //   postScript: null//交易附言
      // },
      bankFee: null,
      rules: {
        bankAccount: [
          { required: true, message: "收款账号不能为空", trigger: "change" }
        ],
        fundNo: [
          { required: true, message: "银行资金账号不能为空", trigger: "change" }
        ],
        rcvBankNo: [
          { required: true, message: "收款行号不能为空", trigger: "change" }
        ],
        rcvBankName: [
          { required: true, message: "收款行名不能为空", trigger: "change" }
        ],
        rcvAcctName: [
          { required: true, message: "收款户名不能为空", trigger: "change" }
        ],
        amount: [
          { required: true, message: "请输入出金金额", trigger: "change" },
          { validator: checkAmount, trigger: "blur" }
        ],
        pwd: [{ required: true, message: "请输入资金密码", trigger: "change" }]
      },
      infoIdOptions: {},
      myAccount: {
        avilibleMargin: "--",
        balance: "--",
        canBeMargin: "--",
        closePl: "--",
        createTime: "--",
        frozenFunds: "--",
        lastBalance: "--",
        occupyMargin: "--",
        procedureFee: "--",
        risk: "--",
        swapsFee: "--"
      },
      isDisable: false,
      isQyBank: "",
      isQyInfo: 0, // 是否信息上报
      firmId: "",
      timer: null
    };
  },
  filters: {
    formatDigit(e) {
      if (isNaN(e)) {
        return e;
      } else {
        return Number(e).toFixed(2);
      }
    },
    formatDigit1(e) {
      if (isNaN(e)) {
        return e;
      } else {
        if (Number(e) >= 0) {
          return Number(e).toFixed(2);
        } else {
          return "--";
        }
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  mounted() {
    this.time = window.g.AMOUNT_TIME;
    this.getFirmListByUserId();
    this.getMyAsset();
    this.getSignInfo();
    this.getfindTrader();
  },
  methods: {
    getFirmListByUserId() {
      http.postFront(protocolFwd.param_getFirmListByUserId).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value) {
            this.firmId = value[0].firmId;
          } else {
            this.firmId = "";
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            this.isQyBank = value.signFlag;
            this.form.bankAccount = value.bankAccount;
          } else {
            // this.isQyBank =
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 账户资金查询
    getMyAsset() {
      http.getRes(protocolFwd.param_getMyAsset).then(response => {
        const that = this;
        const { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value) {
            that.myAccount = value;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 信息上报状态查询
    getfindTrader() {
      http.postFront(protocolFwd.param_findTrader).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          if (value) {
            //  1：交易商正常，  9:交易商开户在途  8:交易商开户失败
            this.isQyInfo = value.status;
          } else {
            this.isQyInfo = 0;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitApply() {
      let that = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.isDisable = true;
          protocolFwd.param_fundOutByMarket.param.amount = that.form.amount;
          protocolFwd.param_fundOutByMarket.param.firmId = that.firmId;
          protocolFwd.param_fundOutByMarket.param.pwd = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" + that.form.pwd
          );
          http.postFront(protocolFwd.param_fundOutByMarket).then(response => {
            const { code, message, value } = response.data;
            if (Number(code) === 0) {
              that.isDisable = false;
              that.$EL_MESSAGE(message);
              // 资金轮询
              if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
              }
              that.timer = setInterval(() => {
                that.getMyAsset();
              }, 1000);
              that.$refs["form"].resetFields();
            } else {
              that.isDisable = false;
              that.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.el-input {
  width: 600px !important;
}
.withdraw {
  padding: 0 15px;
}
.withdraw .myWithdraw {
  float: left;
  line-height: 40px;
}
.withdrawTitle {
  overflow: hidden;
}
.withdrawTitle .myWithdraw p {
  font-size: 16px;
}
.withdrawContent {
  width: 80%;
}
.colorFont {
  // color: #cf4640;
  color: red;
  font-weight: 700;
  p {
    font-size: 18px;
  }
}
.itemAccount {
  padding: 20px 0;
  .bottomModel {
    color: #606266;
    width: 70%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.bankTips .desc {
  text-indent: 2em;
}
.bankTips {
  // color: #1989fa;
  margin-top: 10px;
  line-height: 35px;
  .desc {
    color: red;
    font-weight: 700;
  }
  .title {
    font-weight: 700;
    color: red;
  }
}
.submitButton /deep/ .el-button{
  background: linear-gradient(0deg, #4C87F0 1%, #2D69D3 100%);
}
</style>
